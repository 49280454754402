import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import Collapse, { Panel } from 'rc-collapse'
import 'rc-collapse/assets/index.css'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import media from '../../styles/media'
import SEO from '~/components/seo'
import ProductForm from '~/components/ProductForm'
import SocialShare from '~/utils/social'
import {
  LargerHeading,
  LargeProdImg,
  Divider,
  DetailsText,
  AboutText,
  BodyText,
  SubHeadingText,
  CrumbCon,
  Grid,
  Product,
  ProductImage,
  Title,
  PriceTag,
  BuyLink,
  ProductImgContainer,
} from '../../styles/index'
import { isMobile } from 'react-device-detect'
import Navigation from '../../components/Navigation'
import ProductReviews from '../../components/Reviews'

const InfoCon = styled.div`
  margin: var(--gutter-m) var(--gutter-s);
  .rc-collapse {
    background-color: var(--white);
    border: none;
    border-bottom: 1px solid rgba(178, 178, 178, 0.5);
    border-radius: 0;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    .rc-collapse-item {
      padding: 1em 0 1em;
      border-top: none;
      border-bottom: 1px solid rgba(178, 178, 178, 0.5);
      .rc-collapse-header {
        font-family: 'neue-haas-grotesk-display-bold';
        padding: 0.5em 0;
        font-size: 18px;
        line-height: 27px;
        color: var(--black);
        i {
          margin-right: 20px;
        }
      }
      .rc-collapse-header:focus {
        outline: none;
      }
      .rc-collapse-content {
        background-color: var(--white);
        color: var(--black);
        padding: 0;
      }
    }
  }
  ${media.laptop`
    margin: var(--gutter-l) 15%;
    .react-tabs {
      min-height: 30vh;
      ul {
        text-align: center;
        padding: 0 var(--gutter-m);
        border-bottom: 1px solid var(--light-grey);
        margin: 0 0 var(--gutter-m);
        justify-content: space-around;
        display: flex;
        li {
          width: 33.33%;
          margin: 0 var(--gutter-s);
        }
      }
      .react-tabs__tab--selected {
        background: transparent;
        border: none;
        color: var(--blue);
        font-family: 'neue-haas-grotesk-display-bold';
        border-bottom: 3px solid var(--blue);
      }
    }
  `}
`

const ProductCon = styled.div`
  margin: var(--gutter-s);
  display: block;
  table {
    tr {
      td {
        padding-right: 5px;
      }
    }
  }
  ${media.laptop`
    margin: var(--gutter-m);
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: var(--gutter-m);
    table {
      tr {
        td {
          padding-right: 30px;
        }
      }
    }
  `}
`

const ProductPage = ({ data, location }) => {
  const product = data.shopifyProduct
  const productTags = product.tags
  const [width, setWidth] = useState(0)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setWidth(window.innerWidth)
    }
  }, [width])

  const similarProducts = []
  for (const tag of productTags) {
    for (const related of data.allShopifyProduct.edges) {
      const relatedTags = related.node.tags
      if (relatedTags.includes(tag)) {
        similarProducts.indexOf(related) === -1
          ? similarProducts.push(related)
          : console.log('')
      }
    }
  }

  const relatedProductsMob = similarProducts.splice(0, 2)
  const relatedProducts = similarProducts.splice(0, 3)

  var str = product.description
  var splitTabs = str.split('[custom tab]')

  function expandIcon({ isActive }) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="plus"
        width="20"
        height="20"
        viewBox="0 0 160 160"
        fill="currentColor"
        style={{
          margin: '0  0.5em',
          position: 'absolute',
          right: '2em',
          transition: 'transform .3s',
          transform: `rotate(${isActive ? 135 : 0}deg)`,
          transformOrigin: 'center',
        }}
      >
        <rect className="vertical-line" x="70" width="20" height="160" />
        <rect className="horizontal-line" y="70" width="160" height="20" />
      </svg>
    )
  }

  return (
    <>
      <Navigation />
      <SEO title={product.title} description={product.description} />
      <CrumbCon>
        <Breadcrumb>
          <Breadcrumb.Item href="/products">
            <p className="blue backArrow">
              &#x25C2;
            </p>
          </Breadcrumb.Item>
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <p>/</p>
          <Breadcrumb.Item href="/products">Our Products</Breadcrumb.Item>
          <p>/</p>
          <Breadcrumb.Item>{product.title}</Breadcrumb.Item>
        </Breadcrumb>
      </CrumbCon>
      <ProductCon>
        {isMobile && <LargerHeading>{product.title}</LargerHeading>}
        <div>
          {product.images.map(image => (
            <LargeProdImg
              fluid={image.localFile.childImageSharp.fluid}
              key={image.id}
              alt={product.title}
            />
          ))}
        </div>
        <div>
          {!isMobile && <LargerHeading>{product.title}</LargerHeading>}

          <DetailsText className="prodDetails">
            {splitTabs[0].substr(0, 200)}...
          </DetailsText>

          <table>
            <tbody>
              <tr>
                <td>
                  <DetailsText>Product code:</DetailsText>
                </td>
                <td>
                  <DetailsText>{product.variants[0].sku}</DetailsText>
                </td>
              </tr>
              <tr>
                <td>
                  <DetailsText>Availability:</DetailsText>
                </td>
                <td>
                  {product.variants[0].availableForSale && (
                    <DetailsText>In Stock</DetailsText>
                  )}
                  {!product.variants[0].availableForSale && (
                    <DetailsText>Sold Out</DetailsText>
                  )}
                </td>
              </tr>
            </tbody>
          </table>

          <Divider className="spaced" />

          <ProductForm product={product} />

          <Divider className="spaced low" />

          <SocialShare />
        </div>
      </ProductCon>

      <InfoCon>
        {!isMobile && (
          <Tabs>
            <TabList>
              <Tab>
                <AboutText>Description</AboutText>
              </Tab>
              <Tab>
                <AboutText>Compatibility</AboutText>
              </Tab>
              <Tab>
                <AboutText>Customer Reviews</AboutText>
              </Tab>
            </TabList>

            <TabPanel>
              <BodyText className="black shorter">{splitTabs[0]}</BodyText>
            </TabPanel>
            <TabPanel>
              <BodyText className="black shorter">
                {splitTabs[1].replace('[/custom tab]', '')}
              </BodyText>
            </TabPanel>
            <TabPanel>
              <ProductReviews product={product} />
            </TabPanel>
          </Tabs>
        )}
        {isMobile && (
          <>
            <Divider />
            <Collapse accordion={true} expandIcon={expandIcon}>
              <Panel
                header="Description"
                headerClass="my-header-class mouse-link"
              >
                {splitTabs[0]}
              </Panel>
              <Panel
                header="Compatibility"
                headerClass="my-header-class mouse-link"
              >
                {splitTabs[1].replace('[/custom tab]', '')}
              </Panel>
              <Panel
                header="Customer Reviews"
                headerClass="my-header-class mouse-link"
              >
                <BodyText className="black shorter">
                  <ProductReviews product={product} />
                </BodyText>
              </Panel>
            </Collapse>
          </>
        )}
      </InfoCon>

      <SubHeadingText className="center">Related Products</SubHeadingText>
      {!isMobile && (
        <Grid className="bottom-space related">
          {relatedProducts ? (
            relatedProducts.map(
              ({
                node: {
                  id,
                  handle,
                  title,
                  images: [firstImage],
                  variants: [firstVariant],
                },
              }) => (
                <a
                  href={`/product/${handle}/`}
                  key={id}
                  className="productItem"
                  id={`product product-${id}`}
                >
                  <Product>
                    <ProductImgContainer>
                      {firstImage && firstImage.localFile && (
                        <ProductImage
                          fluid={firstImage.localFile.childImageSharp.fluid}
                          alt={handle}
                        />
                      )}
                    </ProductImgContainer>
                    <Title>{title}</Title>
                    <PriceTag>
                      R
                      {firstVariant.price
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                    </PriceTag>
                    <BuyLink to={`/product/${handle}/`}>Add to Cart</BuyLink>
                  </Product>
                </a>
              )
            )
          ) : (
            <p>No related products found!</p>
          )}
        </Grid>
      )}
      {isMobile && width < 600 && (
        <Grid className="bottom-space">
          {relatedProductsMob ? (
            relatedProductsMob.map(
              ({
                node: {
                  id,
                  handle,
                  title,
                  images: [firstImage],
                  variants: [firstVariant],
                },
              }) => (
                <a
                  href={`/product/${handle}/`}
                  key={id}
                  className="productItem"
                  id={`product product-${id}`}
                >
                  <Product>
                    <ProductImgContainer>
                      {firstImage && firstImage.localFile && (
                        <ProductImage
                          fluid={firstImage.localFile.childImageSharp.fluid}
                          alt={handle}
                        />
                      )}
                    </ProductImgContainer>
                    <Title>{title}</Title>
                    <PriceTag>R{firstVariant.price}</PriceTag>
                    <BuyLink to={`/product/${handle}/`}>Add to Cart</BuyLink>
                  </Product>
                </a>
              )
            )
          ) : (
            <p>No related products found!</p>
          )}
        </Grid>
      )}
      {isMobile && width >= 600 && (
        <Grid className="bottom-space">
          {relatedProducts ? (
            relatedProducts.map(
              ({
                node: {
                  id,
                  handle,
                  title,
                  images: [firstImage],
                  variants: [firstVariant],
                },
              }) => (
                <a
                  href={`/product/${handle}/`}
                  key={id}
                  className="productItem"
                  id={`product product-${id}`}
                >
                  <Product>
                    <ProductImgContainer>
                      {firstImage && firstImage.localFile && (
                        <ProductImage
                          fluid={firstImage.localFile.childImageSharp.fluid}
                          alt={handle}
                        />
                      )}
                    </ProductImgContainer>
                    <Title>{title}</Title>
                    <PriceTag>R{firstVariant.price}</PriceTag>
                    <BuyLink to={`/product/${handle}/`}>Add to Cart</BuyLink>
                  </Product>
                </a>
              )
            )
          ) : (
            <p>No related products found!</p>
          )}
        </Grid>
      )}
    </>
  )
}

export const query = graphql`
  query($handle: String!) {
    shopifyProduct(handle: { eq: $handle }) {
      id
      title
      handle
      productType
      description
      shopifyId
      tags
      options {
        id
        name
        values
      }
      variants {
        id
        title
        price
        sku
        availableForSale
        shopifyId
        selectedOptions {
          name
          value
        }
      }
      priceRange {
        minVariantPrice {
          amount
          currencyCode
        }
        maxVariantPrice {
          amount
          currencyCode
        }
      }
      images {
        originalSrc
        id
        localFile {
          childImageSharp {
            fluid(maxWidth: 910) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
    allShopifyProduct(filter: { tags: { eq: "Laser Toner Cartridge" } }) {
      edges {
        node {
          id
          title
          handle
          productType
          tags
          images {
            id
            originalSrc
            localFile {
              childImageSharp {
                fluid(maxWidth: 910) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
          variants {
            sku
            price
          }
        }
      }
    }
  }
`

export default ProductPage
