import React, { useState, useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import styled from 'styled-components'
import media from '~/styles/media'
import { BodyText, Button, Divider } from '../../styles'
import firebase from '../../utils/firebase'
import ProductReviewForm from './form'

const ReviewInfo = styled.div`
  display: contents;
  padding-bottom: var(--gutter-button);
  .reviewStars {
    display: flex;
    .based {
      margin-left: 15px;
    }
  }

  ${media.laptop`
  display: flex;
  justify-content: space-between;
  
  `}
`

const ReviewCon = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
`

const ProductReviews = ({ product }) => {
  const [reviewItems, setReviewItems] = useState([])
  const [modal, setModal] = useState(false)

  useEffect(() => {
    firebase
      .firestore()
      .collection('reviews')
      .onSnapshot(snapshot => {
        const reviewList = snapshot.docs.map(rev => ({
          id: rev.id,
          ...rev.data(),
        }))
        setReviewItems(reviewList)
      })
  }, [])

  const openReviewForm = () => {
    setModal(true)
  }
  const closeReviewForm = () => {
    setModal(false)
  }

  const filteredItems = []
  let filteredRating = 0

  for (const item of reviewItems) {
    if (item.handle === product.handle) {
      filteredItems.push(item)
      filteredRating = filteredRating + parseInt(item.rating)
    }
  }

  const averageStars = parseInt(filteredRating / filteredItems.length).toFixed(
    0
  )

  return (
    <div>
      <ReviewInfo>
        {isMobile && (
          <>
            {filteredItems.length > 0 && (
              <div className="reviewStars">
                {averageStars === '5' && (
                  <BodyText className="blue">★★★★★</BodyText>
                )}
                {averageStars === '4' && (
                  <BodyText className="blue">★★★★☆</BodyText>
                )}
                {averageStars === '3' && (
                  <BodyText className="blue">★★★☆☆</BodyText>
                )}
                {averageStars === '2' && (
                  <BodyText className="blue">★★☆☆☆</BodyText>
                )}
                {averageStars === '1' && (
                  <BodyText className="blue">★☆☆☆☆</BodyText>
                )}{' '}
                <br />
                <br />
                <BodyText className="based black">
                  Based on {filteredItems.length} reviews
                </BodyText>
              </div>
            )}
          </>
        )}
        {!isMobile && (
          <>
            {filteredItems.length > 0 && (
              <div className="reviewStars">
                {averageStars === '5' && (
                  <BodyText className="blue">★★★★★</BodyText>
                )}
                {averageStars === '4' && (
                  <BodyText className="blue">★★★★☆</BodyText>
                )}
                {averageStars === '3' && (
                  <BodyText className="blue">★★★☆☆</BodyText>
                )}
                {averageStars === '2' && (
                  <BodyText className="blue">★★☆☆☆</BodyText>
                )}
                {averageStars === '1' && (
                  <BodyText className="blue">★☆☆☆☆</BodyText>
                )}{' '}
                <BodyText className="based black">
                  Based on {filteredItems.length} reviews
                </BodyText>
              </div>
            )}
          </>
        )}
        {isMobile && (
          <>
            {filteredItems.length === 0 && (
              <BodyText className="black">
                {' '}
                0 stars - <br />
                Based on {filteredItems.length} reviews
              </BodyText>
            )}
          </>
        )}
        {!isMobile && (
          <>
            {filteredItems.length === 0 && (
              <BodyText className="black">
                {' '}
                0 stars - Based on {filteredItems.length} reviews
              </BodyText>
            )}
          </>
        )}
        <div>
          {!modal && (
            <Button className="clear bold" onClick={openReviewForm}>
              Write A Review
            </Button>
          )}
          {modal && (
            <Button className="bold" onClick={closeReviewForm}>
              Close
            </Button>
          )}
        </div>
      </ReviewInfo>
      <Divider className="spaced" />
      {modal && <ProductReviewForm product={product} />}
      {reviewItems ? (
        reviewItems
          .filter(review => review.handle === product.handle)
          .map(review => (
            <>
              <ReviewCon key={review.id}>
                <div className="nameStar">
                  <BodyText className="black">{review.name}</BodyText>
                  {review.rating === '5' && (
                    <BodyText className="blue">★★★★★</BodyText>
                  )}
                  {review.rating === '4' && (
                    <BodyText className="blue">★★★★☆</BodyText>
                  )}
                  {review.rating === '3' && (
                    <BodyText className="blue">★★★☆☆</BodyText>
                  )}
                  {review.rating === '2' && (
                    <BodyText className="blue">★★☆☆☆</BodyText>
                  )}
                  {review.rating === '1' && (
                    <BodyText className="blue">★☆☆☆☆</BodyText>
                  )}
                </div>
                <div className="info">
                  <BodyText className="black bold">{review.title}</BodyText>
                  <BodyText className="black">{review.message}</BodyText>
                </div>
              </ReviewCon>
              <Divider className="spaced" />
            </>
          ))
      ) : (
        <BodyText className="black">No Products found!</BodyText>
      )}
      {!filteredItems && <BodyText className="black">No reviews yet!</BodyText>}
    </div>
  )
}

export default ProductReviews
