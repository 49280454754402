import React, { useState, useContext, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import media from '~/styles/media'
import '@material/react-dialog/dist/dialog.min.css'
import Dialog, { DialogTitle, DialogContent } from '@material/react-dialog'

import StoreContext from '~/context/StoreContext'
import {
  ProductPrice,
  Button,
  QuantityInput,
  IncrementButton,
} from '../../styles'
import { navigateTo } from 'gatsby'

const ProdForm = styled.div`
  .mdc-dialog {
    height: 110px;
    margin: var(--gutter-s);
    justify-content: flex-end;
    width: auto;
    .mdc-dialog__surface {
      background-color: var(--white);
      border-left: 5px solid var(--blue);
      border-radius: 2px;
    }
    .mdc-dialog__title {
      font-size: 30px;
      line-height: 45px;
      font-family: 'neue-haas-grotesk-display-bold';
    }
    .mdc-dialog__content {
      font-size: 18px;
      line-height: 27px;
    }
    .mdc-dialog__title,
    .mdc-dialog__content {
      color: var(--black);
    }
  }
  ${media.laptop`
    .mdc-dialog {
      height: 145px;
      margin: var(--gutter-s);
      justify-content: flex-end;
      width: 95%;
    }
  `}
`

const ProductDiv = styled.div`
  .row {
    display: block;
    .number {
      width: 100%;
      height: 50px;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      letter-spacing: 0.05em;
      color: var(--black);
      border: 1px solid var(--light-grey);
      margin-right: 2em;
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      input[type='number'] {
        -moz-appearance: textfield;
      }
    }
  }

  ${media.laptop`
    .row {
      display: flex;
      .number {
        margin-bottom: 0;
        width: 200px;
      }
    }
  `}
`

const ProductForm = ({ product }) => {
  const {
    variants,
    variants: [initialVariant],
  } = product
  const [variant] = useState({ ...initialVariant })
  const [quantity, setQuantity] = useState(1)
  const [openDialog] = useState(false)
  const {
    addVariantToCart,
    store: { client, adding },
  } = useContext(StoreContext)

  const productVariant =
    client.product.helpers.variantForOptions(product, variant) || variant
  const [available, setAvailable] = useState(productVariant.availableForSale)

  const checkAvailability = useCallback(
    productId => {
      client.product.fetch(productId).then(fetchedProduct => {
        // this checks the currently selected variant for availability
        const result = fetchedProduct.variants.filter(
          variant => variant.id === productVariant.shopifyId
        )
        if (result.length > 0) {
          setAvailable(result[0].available)
        }
      })
    },
    [client.product, productVariant.shopifyId, variants]
  )

  useEffect(() => {
    checkAvailability(product.shopifyId)
  }, [productVariant, checkAvailability, product.shopifyId])

  const handleQuantityChange = ({ target }) => {
    setQuantity(target.value)
  }

  const handleAddToCart = () => {
    addVariantToCart(productVariant.shopifyId, quantity)
    navigateTo('/cart')
  }

  // const checkDisabled = (name, value) => {
  //   const match = find(variants, {
  //     selectedOptions: [
  //       {
  //         name: name,
  //         value: value,
  //       },
  //     ],
  //   })
  //   if (match === undefined) return true
  //   if (match.availableForSale === true) return false
  //   return true
  // }

  const minusClick = () => {
    setQuantity(quantity - 1)
  }

  const plusClick = () => {
    setQuantity(quantity + 1)
  }

  return (
    <ProdForm>
      <Dialog open={openDialog}>
        <DialogTitle>Success</DialogTitle>
        <DialogContent>{product.title} added to cart</DialogContent>
      </Dialog>
      <ProductDiv>
        <ProductPrice>
          R {variant.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
        </ProductPrice>

        <div className="row">
          <div className="number">
            <IncrementButton className="left" onClick={() => minusClick()}>
              -
            </IncrementButton>
            <QuantityInput
              className="long"
              type="number"
              id="quantity"
              name="quantity"
              min="1"
              step="1"
              onChange={handleQuantityChange}
              value={quantity}
            />
            <IncrementButton className="right" onClick={() => plusClick()}>
              +
            </IncrementButton>
          </div>
          {/* <Button
            className="form-button"
            type="submit"
            disabled={!available || adding}
            onClick={handleAddToCart}
          >
            Buy Now
          </Button> */}
          <Button
            className="form-button"
            type="submit"
            disabled={!available || adding}
            onClick={handleAddToCart}
          >
            Add to Cart
          </Button>
          {!available && <p>This Product is out of Stock!</p>}
        </div>
      </ProductDiv>
    </ProdForm>
  )
}

ProductForm.propTypes = {
  product: PropTypes.shape({
    descriptionHtml: PropTypes.string,
    handle: PropTypes.string,
    id: PropTypes.string,
    shopifyId: PropTypes.string,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        originalSrc: PropTypes.string,
      })
    ),
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        values: PropTypes.arrayOf(PropTypes.string),
      })
    ),
    productType: PropTypes.string,
    title: PropTypes.string,
    variants: PropTypes.arrayOf(
      PropTypes.shape({
        availableForSale: PropTypes.bool,
        id: PropTypes.string,
        price: PropTypes.string,
        title: PropTypes.string,
        shopifyId: PropTypes.string,
        selectedOptions: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            value: PropTypes.string,
          })
        ),
      })
    ),
  }),
  addVariantToCart: PropTypes.func,
}

export default ProductForm
