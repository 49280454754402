import React, { useEffect } from 'react'
import styled from 'styled-components'
import * as clipboard from 'clipboard-polyfill/text'

const SocialCon = styled.div`
  display: flex;
  margin-bottom: 20px;
  height: 2vh;

  @import url('https://fonts.googleapis.com/css?family=Roboto:400,400i,700');
  .challenge {
    width: 541px;
    height: 22px;
    position: absolute;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    top: 569.5px;
    left: 755px;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
  }
  .challenge .number {
    float: right;
  }
  .challenge .daily {
    float: left;
  }

  body {
    height: 100vh;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    font-family: Roboto, sans-serif;
    color: #fff;
    font-size: 18px;
  }

  .content {
    width: 800px;
    height: 600px;
    background: white;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    border-radius: 5px;
  }
  .content .shareButton.main .share,
  .content .shareButton.main .close,
  .content .shareButton.main .check {
    position: absolute;
    top: 1rem;
    left: 1rem;
    -webkit-transition: all 150ms;
    transition: all 150ms;
  }
  .content .shareButton.main .share,
  .content .shareButton.main.open .close,
  .content .shareButton.main.sent .check {
    -webkit-transform: rotate(0) scale(1);
    transform: rotate(0) scale(1);
    opacity: 1;
  }
  .content .shareButton.main .close,
  .content .shareButton.main.open .share,
  .content .shareButton.main .check,
  .content .shareButton.main.sent .share {
    opacity: 0;
    -webkit-transform: rotate(90deg) scale(0);
    transform: rotate(90deg) scale(0);
  }

  .shareButton,
  .shareButton.open {
    border: none;
    border-radius: 50%;
    background: var(--blue);
    padding: 1rem;
    overflow: hidden;
    outline: none;
    margin: 0.5rem;
    width: 4px;
    height: 4px;
    box-sizing: content-box;
    -webkit-transition: all 200ms;
    transition: all 200ms;
    position: relative;
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  .shareButton:hover,
  .shareButton.open:hover {
    -webkit-transform: scale(1.1) translateY(-3px);
    transform: scale(1.1) translateY(-3px);
    background: var(--dark-blue);
  }
  .shareButton svg,
  .shareButton.open svg {
    display: block;
    fill: white;
    width: 4px;
    height: 4px;
    margin: -3px -4px;
    opacity: 1;
    -webkit-transition: all 150ms;
    transition: all 150ms;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  .fb,
  .shareButton.open.ig {
    -webkit-transition-delay: 100ms;
    transition-delay: 100ms;
  }

  .tw,
  .shareButton.open.tw {
    -webkit-transition-delay: 50ms;
    transition-delay: 50ms;
  }

  .ig,
  .shareButton.open.fb {
    -webkit-transition-delay: 0ms;
    transition-delay: 0ms;
  }

  .fb,
  .tw,
  .ig {
    width: 0;
    height: 0;
    overflow: hidden;
    padding: 0;
    margin: 0;
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  .fb svg,
  .tw svg,
  .ig svg {
    width: 0;
    height: 0;
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
`

const SocialShare = () => {
  useEffect(() => {
    const shareButton = document.querySelectorAll('button.shareButton')

    if (shareButton[0]) {
      shareButton[0].addEventListener('click', e => {
        for (let i = 0; i < shareButton.length; i++) {
          shareButton[i].classList.toggle('open')
          shareButton[0].classList.remove('sent')
        }
      })

      for (let i = 1; i < shareButton.length; i++) {
        shareButton[i].addEventListener('click', e => {
          for (let i = 0; i < shareButton.length; i++) {
            shareButton[i].classList.toggle('open')
          }
          shareButton[0].classList.toggle('sent')
        })
      }
    }
  }, [])

  return (
    <SocialCon className="content">
      <button class="shareButton main">
        <svg
          class="share"
          style={{ width: '12px', height: '12px' }}
          viewBox="0 0 24 24"
        >
          <path d="M18,16.08C17.24,16.08 16.56,16.38 16.04,16.85L8.91,12.7C8.96,12.47 9,12.24 9,12C9,11.76 8.96,11.53 8.91,11.3L15.96,7.19C16.5,7.69 17.21,8 18,8A3,3 0 0,0 21,5A3,3 0 0,0 18,2A3,3 0 0,0 15,5C15,5.24 15.04,5.47 15.09,5.7L8.04,9.81C7.5,9.31 6.79,9 6,9A3,3 0 0,0 3,12A3,3 0 0,0 6,15C6.79,15 7.5,14.69 8.04,14.19L15.16,18.34C15.11,18.55 15.08,18.77 15.08,19C15.08,20.61 16.39,21.91 18,21.91C19.61,21.91 20.92,20.61 20.92,19A2.92,2.92 0 0,0 18,16.08Z" />
        </svg>
      </button>
      {typeof window !== 'undefined' && (
        <button class="shareButton fb">
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg style={{ width: '12px', height: '12px' }} viewBox="0 0 24 24">
              <path d="M17,2V2H17V6H15C14.31,6 14,6.81 14,7.5V10H14L17,10V14H14V22H10V14H7V10H10V6A4,4 0 0,1 14,2H17Z" />
            </svg>
          </a>
        </button>
      )}
      {typeof window !== 'undefined' && (
        <button class="shareButton tw">
          <a
            href={`https://twitter.com/share?url=${window.location.href}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg style={{ width: '12px', height: '12px' }} viewBox="0 0 24 24">
              <path d="M22.46,6C21.69,6.35 20.86,6.58 20,6.69C20.88,6.16 21.56,5.32 21.88,4.31C21.05,4.81 20.13,5.16 19.16,5.36C18.37,4.5 17.26,4 16,4C13.65,4 11.73,5.92 11.73,8.29C11.73,8.63 11.77,8.96 11.84,9.27C8.28,9.09 5.11,7.38 3,4.79C2.63,5.42 2.42,6.16 2.42,6.94C2.42,8.43 3.17,9.75 4.33,10.5C3.62,10.5 2.96,10.3 2.38,10C2.38,10 2.38,10 2.38,10.03C2.38,12.11 3.86,13.85 5.82,14.24C5.46,14.34 5.08,14.39 4.69,14.39C4.42,14.39 4.15,14.36 3.89,14.31C4.43,16 6,17.26 7.89,17.29C6.43,18.45 4.58,19.13 2.56,19.13C2.22,19.13 1.88,19.11 1.54,19.07C3.44,20.29 5.7,21 8.12,21C16,21 20.33,14.46 20.33,8.79C20.33,8.6 20.33,8.42 20.32,8.23C21.16,7.63 21.88,6.87 22.46,6Z" />
            </svg>
          </a>
        </button>
      )}
      {typeof window !== 'undefined' && (
        <button
          class="shareButton ig"
          onClick={clipboard.writeText(window.location.href)}
        >
          <svg style={{ width: '12px', height: '12px' }} viewBox="0 0 24 24">
            <path d="M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z" />
          </svg>
        </button>
      )}
    </SocialCon>
  )
}

export default SocialShare
