import React, { useState } from 'react'
import styled from 'styled-components'
import {
  Button,
  Divider,
  FormInput,
  FormLabel,
  FormTextArea,
} from '../../styles'
import firebase from '../../utils/firebase'

const ReviewFormCon = styled.div`
  form {
    display: block;
  }
  fieldset,
  label {
    margin: 0;
    padding: 0;
  }

  .rating {
    border: none;
    float: left;
    margin-right: 20px;
  }

  .rating > input {
    display: none;
  }
  .rating > label:before {
    margin: 5px;
    margin-top: -8px;
    font-size: 1.25em;
    display: inline-block;
    content: '★';
  }

  .rating > .half:before {
    content: '\f089';
    position: absolute;
  }

  .rating > label {
    color: #ddd;
    float: right;
  }

  .rating > input:checked ~ label,
  .rating:not(:checked) > label:hover,
  .rating:not(:checked) > label:hover ~ label {
    color: var(--blue);
    content: '★' !important;
  }

  .rating > input:checked + label:hover,
  .rating > input:checked ~ label:hover,
  .rating > label:hover ~ input:checked ~ label,
  .rating > input:checked ~ label:hover ~ label {
    color: var(--blue);
    content: '★' !important;
  }
`

const ProductReviewForm = ({ product }) => {
  const modal = useState(true)

  const [state, setState] = useState({
    handle: product.handle,
    name: '',
    email: '',
    rating: '',
    title: '',
    message: '',
  })

  const save = e => {
    e.preventDefault()

    firebase
      .firestore()
      .collection('reviews')
      .add(state)
      .then(ref => {
        // console.log('Added review with ID: ', ref.id)
      })

    document.getElementById('reviewForm').reset()
  }

  const handleInput = e => {
    if (e.target.name === 'name') {
      setState({ ...state, name: e.target.value })
    }

    if (e.target.name === 'email') {
      setState({ ...state, email: e.target.value })
    }

    if (e.target.name === 'rating') {
      setState({ ...state, rating: e.target.value })
    }

    if (e.target.name === 'title') {
      setState({ ...state, title: e.target.value })
    }

    if (e.target.name === 'message') {
      setState({ ...state, message: e.target.value })
    }
  }

  return (
    <ReviewFormCon>
      {modal && (
        <form onSubmit={save} id="reviewForm">
          <label htmlFor="handle">
          <input
            type="text"
            name="handle"
            id="handle"
            value={product.handle}
            hidden
          />
          </label>
          <FormLabel>Name</FormLabel>
          <br />
          <FormInput
            type="text"
            name="name"
            id="name"
            placeholder="* Name"
            onChange={handleInput}
          />
          <br />
          <FormLabel>Email</FormLabel>
          <br />
          <FormInput
            type="text"
            name="email"
            id="email"
            placeholder="* Email"
            onChange={handleInput}
          />
          <br />
          <FormLabel className="rating">Rating</FormLabel>
          <div id="reviewStars-input" class="rating">
            <input
              type="radio"
              id="star5"
              name="rating"
              value="5"
              onChange={handleInput}
            />
            <label className="full" for="star5">
            <input
              type="radio"
              id="star4"
              name="rating"
              value="4"
              onChange={handleInput}
            />
            </label>
            <label className="full" for="star4">
            <input
              type="radio"
              id="star3"
              name="rating"
              value="3"
              onChange={handleInput}
            />
            </label>
            <label className="full" for="star3">
            <input
              type="radio"
              id="star2"
              name="rating"
              value="2"
              onChange={handleInput}
            />
            </label>
            <label className="full" for="star2">
            <input
              type="radio"
              id="star1"
              name="rating"
              value="1"
              onChange={handleInput}
            />
            </label>
            {/* <label className="full" for="star1"></label> */}
          </div>{' '}
          <br />
          <br />
          <FormLabel>Review Title</FormLabel>
          <br />
          <FormInput
            type="text"
            name="title"
            id="title"
            placeholder="* Title"
            onChange={handleInput}
          />
          <br />
          <FormLabel>Message (1500)</FormLabel>
          <br />
          <FormTextArea
            type="text"
            name="message"
            id="message"
            placeholder="* Message"
            onChange={handleInput}
          />
          <br />
          <Button type="submit">Submit</Button>
        </form>
      )}
      <Divider className="spaced" />
    </ReviewFormCon>
  )
}

export default ProductReviewForm
